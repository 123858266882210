import { AxiosResponse } from "axios";
import $api from "../http";
import { IUser, IUsers, whoAmI } from "models/User/IUser";

export default class UserService {
  static async getUsers(role?: string): Promise<AxiosResponse<IUsers>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    const params = role ? { role } : {};
    return $api.get<IUsers>("/tracking/users", {
      headers: { ...basicAuthHeader },
      params,
    });
  }
  static async whoAmI(): Promise<AxiosResponse<whoAmI>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.get<whoAmI>("/whoami", {
      headers: { ...basicAuthHeader },
    });
  }
}
