import create from "zustand";
import AddOrderService from "../api/api";
import { Order } from "models/List/TrackingOrders";

interface createOrder {
  createOrderResponse: Order | null;
  createOrderError: string | null;
  createOrder: (number: string) => void;
  isModalOpen: boolean;
  setIsModalOpen: (status: boolean) => void;
}

export const useCreateStore = create<createOrder>((set) => ({
  createOrderResponse: null,
  createOrderError: null,
  isModalOpen: false,
  setIsModalOpen: (status: boolean) => {
    set({ createOrderResponse: null });
    set({ createOrderError: null });
    set({ isModalOpen: status });
  },
  createOrder: async (number: string) => {
    set({ createOrderError: null });

    try {
      const response = await AddOrderService.addOrder(number);
      set({ createOrderResponse: response.data });
    } catch (error) {
      console.error("Error creating order:", error);
      set({ createOrderError: "建立訂單時出錯" });
    }
  },
}));
