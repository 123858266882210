import React from "react";
import { Table, Button, TypographyStylesProvider } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import MySlider from "../../Slider/MySlider";
import { Execution, Attachment } from "../../../models/List/OrdersExecution";
import iconFile from "../../../img/icons/icon-file.png";

interface ExpandedRowProps {
  item: Execution;
  downloadPhotos: (item: Execution) => void;
  downloadLoadingMap: Record<string, boolean>;
}

const documents = (item: Execution) => {
  if (!item.attachments) {
    return [];
  }

  return item.attachments
    .filter((attachment) => {
      return (
        attachment.content.type.startsWith("application") ||
        attachment.content.type.startsWith("text")
      );
    })
    .map((attachment: Attachment) => ({
      link: `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.content.id}/view`,
      text: attachment.name,
      thumbnail: iconFile,
      type: "doc" as const,
    }));
};
const ExpandedRowComponent = ({
  item,
  downloadPhotos,
  downloadLoadingMap,
}: ExpandedRowProps) => {
  return (
    <>
      {item.content && (
        <Table.Tr>
          <Table.Td colSpan={3} style={{ width: "100%" }}>
            <TypographyStylesProvider>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </TypographyStylesProvider>
          </Table.Td>
        </Table.Tr>
      )}
      <Table.Tr>
        <Table.Td colSpan={3}>
          <Table horizontalSpacing="md" verticalSpacing="md" miw="100%">
            <Table.Tbody>
              {item.attachments?.some(
                (att) =>
                  att.content.type !== "image/jpeg" &&
                  att.content.type !== "image/gif" &&
                  att.content.type !== "image/png" &&
                  att.content.type !== "video/mp4"
              ) && (
                <Table.Tr>
                  <MySlider content={documents(item)} type="doc" />
                </Table.Tr>
              )}
              {item.attachments?.some(
                (att) =>
                  att.content.type === "image/jpeg" ||
                  att.content.type === "image/png" ||
                  att.content.type === "image/gif" ||
                  att.content.type === "video/mp4"
              ) && (
                <Table.Tr>
                  <MySlider
                    content={item.attachments
                      .filter(
                        (att) =>
                          att.content.type === "image/jpeg" ||
                          att.content.type === "image/png" ||
                          att.content.type === "image/gif" ||
                          att.content.type === "video/mp4"
                      )
                      .map((img) => ({
                        original:
                          img.content.type === "video/mp4"
                            ? `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${img.content.id}/view`
                            : `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${img.content.id}/view/?format=preview`,
                        thumbnail:
                          img.content.type === "video/mp4"
                            ? `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${img.content.id}/view`
                            : `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${img.content.id}/view/?format=thumbnail`,
                        text: img.name,
                        type:
                          img.content.type === "video/mp4" ? "video" : "photo",
                      }))}
                    type="photo"
                  />

                  <div className="flex justify-center">
                    <Table.Td>
                      <Button
                        onClick={() => downloadPhotos(item)}
                        loading={downloadLoadingMap[item.id]}
                        rightSection={<IconDownload size={20} />}
                      >
                        下载
                      </Button>
                    </Table.Td>
                  </div>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </Table.Td>
      </Table.Tr>
    </>
  );
};

const ExpandedRow = React.memo(ExpandedRowComponent);
export default ExpandedRow;
