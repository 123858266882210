import create from "zustand";

import { IFile } from "../api/model";
import FileService from "../api/index";

import { AxiosResponse } from "axios";

import imageCompression from "browser-image-compression";

interface OrderStoreState {
  selectedOrderId: string | null;
  isTaskModalOpen: boolean;
  isSliderOpen: boolean;
  isExecutionModalOpen: boolean;
  executionId: string | null;
  isModalOpen: boolean;
  formFiles: { [key: string]: File[] };
  setFormFiles: (value: { [key: string]: File[] }) => void;
  resetFormFiles: () => void;

  subLink: string | "";
  fileResponse: any[] | null;
  currentPhotos: { thumbnail: string; original: string }[] | null;
  setCurrentPhotos: (
    photos: { thumbnail: string; original: string }[] | null
  ) => void;

  setPhotoIndex: (index: number) => void;
  setIsExecutionModalOpen: (status: boolean, executionId: string) => void;
  setFileResponse: (file: File) => Promise<AxiosResponse<IFile>>;
  setMultipleFileResponse: (files: File[], fieldId: string) => void;

  postExecutionUpdateResponse: any;
  taskError: any | null;

  setSelectedOrder: (id: string | null) => void;
  openModal: () => void;
  setTaskModalOpen: (status: boolean) => void;
  closeModal: () => void;
  resetFileResponse: () => void;
  photoIndex: number | null;
  customerLogin: string | null;
  setCustomerLogin: (customerLogin: string) => void;

  formIFiles: { [key: string]: IFile[] };
  setFormIFiles: (value: { [key: string]: IFile[] }) => void;
}
const useFileStore = create<OrderStoreState>((set) => ({
  currentPhotos: null,
  orderById: null,
  customerLogin: null,
  postExecutionUpdateResponse: null,
  executionId: null,
  isExecutionModalOpen: false,
  photoIndex: null,
  taskError: null,
  formFiles: {},
  formIFiles: {},
  isTaskModalOpen: true,
  orders: null,
  tasks: null,
  selectedOrderId: null,
  orderExecutions: null,
  isSliderOpen: false,
  isModalOpen: false,
  subLink: "",
  fileResponse: null,
  OneTask: null,

  setMultipleFileResponse: async (files: File[], fieldId: string) => {
    try {
      const responses = await Promise.all(
        files.map(async (file) => {
          return await useFileStore.getState().setFileResponse(file);
        })
      );
      console.log("multi-resp", responses);
      console.log(
        "multi-fileResponse-before",
        useFileStore.getState().fileResponse
      );

      set({
        fileResponse: [
          ...(useFileStore.getState().fileResponse || []),
          ...responses,
        ],
      });
      console.log("multi-fileResponse", useFileStore.getState().fileResponse);

      set((state) => {
        const currentFiles = state.formFiles[fieldId] || [];

        const uniqueFiles = files.filter(
          (file) =>
            !currentFiles.some(
              (existingFile) => existingFile.name === file.name
            )
        );

        return {
          ...state,
          formFiles: {
            ...state.formFiles,
            [fieldId]: [...currentFiles, ...uniqueFiles],
          },
        };
      });
    } catch (error) {
      console.error("Error uploading multiple files:", error);
      throw error;
    }
  },

  setFileResponse: async (file: File): Promise<AxiosResponse<IFile, any>> => {
    try {
      // Опции для сжатия
      const options = {
        maxSizeMB: 1 ? 1 : 1,
        maxWidthOrHeight: 1920 ? 1920 : 1920,
        useWebWorker: true,
      };

      let fileToUpload: File | Blob = file as File;

      // Проверяем тип файла
      if ((file as File).type.startsWith("image/")) {
        const compressedFile = await imageCompression(file as File, options);
        fileToUpload = new File([compressedFile], file.name, {
          type: compressedFile.type,
          lastModified: Date.now(),
        });
      } else {
        console.log("File is not an image, skipping compression.");
      }

      const response = await FileService.fileUpload(fileToUpload as File);
      return response;
    } catch (error) {
      console.error("Error file upload:", error);
      throw error;
    }
  },

  setFormFiles: (newFiles) =>
    set((state) => ({
      formFiles: {
        ...state.formFiles,
        ...newFiles,
      },
    })),
  resetFormFiles: () =>
    set(() => ({
      formFiles: {},
    })),
  setTaskModalOpen: (status: boolean) => set({ isTaskModalOpen: status }),
  setIsExecutionModalOpen: (status: boolean, executionId: string) => {
    set({ isExecutionModalOpen: status, executionId: executionId });
    set({ taskError: null });
    set({ postExecutionUpdateResponse: null });
  },
  setFormIFiles: (newFiles) =>
    set((state) => ({
      formIFiles: {
        ...state.formIFiles,
        ...newFiles,
      },
    })),

  setPhotoIndex: (index: number) => set({ photoIndex: index }),
  setSelectedOrder: (id: string | null) => set({ selectedOrderId: id }),
  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  resetFileResponse: () => set({ fileResponse: null }),
  setCurrentPhotos: (photos) => set({ currentPhotos: photos }),
  setCustomerLogin: (customerLogin: string) =>
    set({ customerLogin: customerLogin }),
}));

export default useFileStore;
