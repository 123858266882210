import {
  Anchor,
  Button,
  FileButton,
  FileInput,
  Grid,
  List,
  Loader,
  Modal,
  TextInput,
  Textarea,
  Title,
  rem,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import useOrderStore from "store/OrderStore";
import { Image } from "@mantine/core";
import { IUpdateExecution } from "models/List/UpdateExecotion";
import { IconUpload, IconX } from "@tabler/icons-react";
import { useParams } from "react-router-dom";

const ModalEditExecutions = () => {
  const {
    executionId,
    isExecutionModalOpen,
    setIsExecutionModalOpen,
    orderExecutions,
    resetFileResponse,
    setMultipleFileResponse,
    postExecutionUpdateResponse,
    postExecutionUpdate,
    taskError,
  } = useOrderStore();
  const { orderId } = useParams<{ orderId: string }>();
  const [uploadfiles, setFiles] = useState<undefined | File[]>(undefined);
  const [error, setError] = useState(false);
  const [updateExecutionLoading, setUpdateExecutionLoading] = useState(false);
  const selectedExecution = orderExecutions?.items.filter(
    (item) => item && item.id == executionId
  )[0];
  const [updateExecutionData, setUpdateExecutionData] =
    useState<IUpdateExecution>({
      name: "",
      content: "",
      attachments: [],
    });
  useEffect(() => {
    if (selectedExecution) {
      setUpdateExecutionData({
        name: selectedExecution.name,
        content: selectedExecution.content,
        attachments: selectedExecution.attachments || [],
      });
    }
  }, [isExecutionModalOpen]);
  const handleDeleteAttachment = (id: string) => {
    const updatedAttachments = updateExecutionData.attachments.filter(
      (attachment) => attachment.content.id !== id
    );

    setUpdateExecutionData({
      ...updateExecutionData,
      attachments: updatedAttachments,
    });
  };

  const [isFileLoading, setFileLoading] = useState(false);

  const updateExecution = () => {
    setUpdateExecutionLoading(true);
    try {
      if (updateExecutionData.name == "") {
        setError(true);
      }
      if (orderId && executionId !== null && updateExecutionData.name !== "") {
        postExecutionUpdate(orderId, executionId, updateExecutionData);
      }
    } catch (error) {
      console.error(error);
    }
    setUpdateExecutionLoading(false);
  };

  return (
    <div>
      <Modal
        opened={isExecutionModalOpen}
        onClose={() => {
          setFiles(undefined);
          setUpdateExecutionData({ name: "", content: "", attachments: [] });
          executionId && setIsExecutionModalOpen(false, executionId);
        }}
        title="编辑事件"
        withCloseButton={true}
        radius="md"
        size="lg"
        centered
      >
        {updateExecutionData && (
          <>
            <TextInput
              label="事件"
              required
              error={error}
              value={updateExecutionData.name}
              onChange={(event) => {
                setUpdateExecutionData((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }));
                setError(false);
              }}
            />
            <Textarea
              autosize
              label="描述"
              value={
                updateExecutionData.content
                  ? updateExecutionData.content.replace(/<br\/?>/g, "\n")
                  : ""
              }
              onChange={(event: { target: { value: string } }) => {
                const newContent = event.target.value.replace(/\n/g, "<br/>");
                setUpdateExecutionData((prevData: any) => ({
                  ...prevData,
                  content: newContent,
                }));
              }}
            />

            {updateExecutionData.attachments.some(
              (att) =>
                att.content.type === "image/jpeg" ||
                att.content.type === "image/png" ||
                att.content.type === "image/gif"
            ) && (
              <>
                <Title order={5}>图片</Title>
                <Grid grow>
                  {updateExecutionData.attachments
                    .filter(
                      (att) =>
                        att.content.type === "image/jpeg" ||
                        att.content.type === "image/png" ||
                        att.content.type === "image/gif"
                    )
                    .map((attachment, index) => (
                      <Grid.Col span={4} key={index}>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            overflow: "hidden",
                          }}
                          onMouseEnter={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            const overlay = target.querySelector(
                              ".overlay"
                            ) as HTMLElement;
                            const button = target.querySelector(
                              ".delete-button"
                            ) as HTMLElement;
                            if (overlay && button) {
                              overlay.style.opacity = "1";
                              button.style.opacity = "1";
                            }
                          }}
                          onMouseLeave={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            const overlay = target.querySelector(
                              ".overlay"
                            ) as HTMLElement;
                            const button = target.querySelector(
                              ".delete-button"
                            ) as HTMLElement;
                            if (overlay && button) {
                              overlay.style.opacity = "0";
                              button.style.opacity = "0";
                            }
                          }}
                        >
                          <Image
                            h={200}
                            w="auto"
                            fit="cover"
                            fallbackSrc="https://placehold.co/600x400?text=Placeholder"
                            src={`${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.content.id}/view?format=thumbnail`}
                            style={{ filter: "brightness(100%)" }}
                          />
                          <div
                            className="overlay"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              opacity: 0,
                              transition: "opacity 0.3s",
                            }}
                          />
                          <Button
                            className="delete-button"
                            onClick={() =>
                              handleDeleteAttachment(attachment.content.id)
                            }
                            color="red"
                            size="compact-xs"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              opacity: 0,
                              transition: "opacity 0.3s",
                            }}
                          >
                            刪除
                          </Button>
                        </div>
                      </Grid.Col>
                    ))}
                </Grid>
              </>
            )}

            {updateExecutionData.attachments.some(
              (att) =>
                att.content.type !== "image/jpeg" &&
                att.content.type !== "image/png" &&
                att.content.type !== "image/gif"
            ) && (
              <>
                <Title order={6} className="pt-2">
                  文件
                </Title>
                <List type="ordered">
                  {updateExecutionData.attachments
                    .filter(
                      (att) =>
                        att.content.type !== "image/jpeg" &&
                        att.content.type !== "image/png"
                    )
                    .map((attachment, index) => (
                      <List.Item key={index}>
                        <div className="flex gap-2 items-center">
                          <Anchor
                            href={`${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.content.id}/view`}
                            target="_blank"
                          >
                            {attachment.content.name}
                          </Anchor>
                          <Button
                            onClick={() =>
                              handleDeleteAttachment(attachment.content.id)
                            }
                            color="red"
                            size="compact-xs"
                          >
                            <IconX
                              style={{ width: rem(16), height: rem(16) }}
                              stroke={1.5}
                            />
                          </Button>
                        </div>
                      </List.Item>
                    ))}
                </List>
              </>
            )}
            <div className="pt-2">
              <FileButton
                multiple
                onChange={async (files) => {
                  try {
                    setFileLoading(true);
                    const currentExecutionId = executionId || "";
                    const currentFormFiles = uploadfiles;
                    const existingAttachmentNames =
                      updateExecutionData.attachments.map(
                        (attachment) => attachment.name
                      );

                    const filterFiles = files.filter(
                      (file) => !existingAttachmentNames.includes(file.name)
                    );
                    const updatedFormFiles = [...filterFiles];

                    setFiles(updatedFormFiles);

                    await setMultipleFileResponse(
                      filterFiles,
                      currentExecutionId
                    );

                    const updatedFileResponse =
                      useOrderStore.getState().fileResponse || [];
                    const updatedFileResponseFiltered =
                      updatedFileResponse.filter((file) =>
                        updatedFormFiles.some(
                          (formFile) => formFile.name === file.name
                        )
                      );

                    if (updatedFileResponseFiltered.length > 0) {
                      setUpdateExecutionData((prevData) => {
                        const currentAttachments = prevData.attachments || [];
                        const newAttachments = [
                          ...currentAttachments,
                          ...updatedFileResponseFiltered.map((file) => ({
                            name: file.name,
                            content: file,
                          })),
                        ];

                        return {
                          ...prevData,
                          attachments: newAttachments,
                        };
                      });
                    }
                    resetFileResponse();
                  } catch (err) {
                    console.error(err);
                  } finally {
                    setFileLoading(false);
                  }
                }}
              >
                {(props) => (
                  <Button
                    variant="outline"
                    {...props}
                    rightSection={<IconUpload size={14} />}
                    loading={isFileLoading}
                  >
                    上传文件
                  </Button>
                )}
              </FileButton>
            </div>
          </>
        )}
        <div className="pt-2 flex items-center gap-5">
          <Button loading={updateExecutionLoading} onClick={updateExecution}>
            保存更改
          </Button>
          <Text
            variant="gradient"
            gradient={{ from: "red", to: "orange", deg: 167 }}
            fw={700}
          >
            {taskError}
          </Text>
          {postExecutionUpdateResponse && (
            <Text
              variant="gradient"
              gradient={{ from: "blue", to: "green", deg: 167 }}
              fw={700}
            >
              已應用更改
            </Text>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ModalEditExecutions;
