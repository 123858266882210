import { useEffect, useState } from "react";
import { Table, Text, Button, Skeleton } from "@mantine/core";
import useOrderStore from "../../../store/OrderStore";
import { Tasks, OneTask } from "../../../models/Task/Task";
import { useNavigate, useParams } from "react-router-dom";

import "./TaskListStyle.css";

interface TaskListProps {
  isLoading: boolean;
}

export function TaskList({ isLoading }: TaskListProps): JSX.Element {
  const { tasks, getOrdersTasks } = useOrderStore();
  const [opened, setOpened] = useState(false);
  const { orderId, taskId } = useParams<{ orderId: string; taskId: string }>();
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState<OneTask | null>(null);
  useEffect(() => {
    if (taskId) {
      setOpened(true);
    }
  }, [taskId]);
  const openTaskModal = (taskId: string) => {
    navigate(`/orders/${orderId}/task/${taskId}`);
    setTaskModalOpen(true);
  };
  const { setTaskModalOpen, resetOneTask } = useOrderStore();
  useEffect(() => {
    if (orderId !== undefined) {
      getOrdersTasks(orderId);
    }
  }, [getOrdersTasks, orderId]);

  const itemsTask = (tasks as Tasks)?.items ?? [];

  return (
    <>
      {itemsTask.length > 0 ? (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>可用操作</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {itemsTask.map((item: OneTask) => (
              <Table.Tr key={item.id}>
                <Table.Td
                  style={
                    item.complete
                      ? {
                          color: "gray",
                          textDecoration: "line-through",
                        }
                      : {}
                  }
                >
                  <Skeleton visible={isLoading}> {item.name}</Skeleton>
                </Table.Td>
                <Table.Td>
                  <Button
                    radius="md"
                    size="xs"
                    disabled={item.complete}
                    onClick={() => {
                      resetOneTask();
                      setOpened(true);
                      setSelectedTask(item);
                      openTaskModal(item.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                      <path d="M7 9l5 -5l5 5" />
                      <path d="M12 4l0 12" />
                    </svg>
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Text mt={4} ta="center">
          所有動作已完成
        </Text>
      )}
    </>
  );
}
