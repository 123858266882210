import React from "react";
import style from "./ChatMessage.module.scss";
import clsx from "clsx";
import DocumentCard from "../DocumentCard/DocumentCard";
import { Image } from "@mantine/core";

interface Props {
  message: string;
  attachment?: any;
  author: string;
  time: string;
  amIAuthor: boolean;
  isLastMessage: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  isFile: boolean;
  onClickImg?: (id: string) => void;
}

const ChatMessage: React.FC<Props> = ({
  message,
  author,
  time,
  amIAuthor,
  isLastMessage,
  ref,
  isFile,
  attachment,
  onClickImg,
}) => {
  const messageWrapperClasses = clsx(style.messageWrapper, {
    [style.messageWrapperOutgoing]: amIAuthor,
    [style.messageWrapperIncoming]: !amIAuthor,
    [style.lastMessage]: isLastMessage,
  });

  const isImage = attachment?.type?.startsWith("image/");

  const formatMessage = (text: string) => {
    return text.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      className={clsx(style.messageContainer, {
        [style.messageContainerOutgoing]: amIAuthor,
        [style.messageContainerIncoming]: !amIAuthor,
      })}
      ref={ref}
    >
      <div className={messageWrapperClasses}>
        <div className={style.messageInfoWrapper}>
          <span className={style.messageTime}>{time}</span>
          {isLastMessage && (
            <span className={style.messageAuthor}>{author}</span>
          )}
        </div>
        {isFile ? (
          isImage ? (
            <Image
              radius="md"
              src={`${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.id}/view/?format=thumbnail`}
              alt={attachment.name}
              className={style.imageAttachment}
              onClick={() => onClickImg?.(attachment.id)}
            />
          ) : (
            <DocumentCard hasDelete={false} documentData={attachment} />
          )
        ) : (
          <p className={style.messageText}>{formatMessage(message)}</p>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
