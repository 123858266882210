import React, { useState, useEffect, useRef } from "react";
import useWebSocketChat from "./useWebSocketChat";
import { Drawer, Textarea } from "@mantine/core";
import { IconMoodSmile, IconPaperclip } from "@tabler/icons-react";
import style from "./ChatComponent.module.scss";
import i18n from "@emoji-mart/data/i18n/ru.json";

// @ts-ignore
import { ReactComponent as Arrow } from "../../../img/send-arrow.svg";

import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentCard from "./DocumentCard/DocumentCard";
import { IUpdateExecution } from "models/List/UpdateExecotion";
import { AddFileBtn } from "./add-file-btn/ui/ui";
import ChatComponent from "./chatComponent/ui/ui";

const ChatFeachersComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const chatIdMatch = pathname.match(/\/chat\/([^/]+)/);
  const chatId = chatIdMatch ? chatIdMatch[1] : "";
  const { messages, postMessage } = useWebSocketChat(chatId);
  const [messageToSend, setMessageToSend] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [updateExecutionData, setUpdateExecutionData] =
    useState<IUpdateExecution>({
      name: "",
      content: "",
      attachments: [],
    });
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const ignoreClickRef = useRef(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ignoreClickRef.current) {
        ignoreClickRef.current = false;
        return;
      }

      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSendMessage = () => {
    if (messageToSend.trim() || updateExecutionData.attachments.length > 0) {
      setMessageToSend("");
      setUpdateExecutionData({
        name: "",
        content: "",
        attachments: [],
      });

      const attachmentsContent = updateExecutionData.attachments.map(
        (attachment: { content: any }) => attachment.content
      );

      postMessage(chatId, messageToSend, attachmentsContent);
    }
  };

  const handleEmojiClick = (emoji: any) => {
    setMessageToSend((prevMessage) => prevMessage + emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => {
      ignoreClickRef.current = true;
      return !prev;
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const RightSection = (
    <div className={style.rightSection}>
      <AddFileBtn
        updateExecutionData={updateExecutionData}
        setUpdateExecutionData={setUpdateExecutionData}
        isBtn={false}
        Icon={IconPaperclip}
      />
    </div>
  );

  const handleDeleteAttachment = (id: string) => {
    const updatedAttachments = updateExecutionData.attachments.filter(
      (attachment: { content: { id: string } }) => attachment.content.id !== id
    );

    setUpdateExecutionData({
      ...updateExecutionData,
      attachments: updatedAttachments,
    });
  };

  const handleClose = () => {
    navigate(pathname.replace(/\/chat\/[^/]+/, ""));
  };
  console.log("chatId", chatId);

  return (
    <Drawer.Root
      opened={!!chatId && chatId !== ""}
      onClose={handleClose}
      position="right"
      size="50vw"
      classNames={{ inner: style.body }}
    >
      <Drawer.Overlay backgroundOpacity={0.0} blur={0} />
      <Drawer.Content>
        <div className={style.allChat} style={{ padding: "8px" }}>
          <div className={style.chatWrapper}>
            <ChatComponent chatId={chatId || ""} messages={messages} />
          </div>
          <div className={style.sendWrapper}>
            <div className={style.sendContainer}>
              <Textarea
                rightSectionWidth={68}
                leftSectionWidth={48}
                style={{ flex: 1 }}
                leftSection={
                  <IconMoodSmile stroke={2} onClick={toggleEmojiPicker} />
                }
                rightSection={RightSection}
                radius="md"
                value={messageToSend}
                placeholder="Write something or press “/” for additional functions...
"
                withAsterisk
                onChange={(e) => setMessageToSend(e.target.value)}
                onKeyDown={handleKeyDown}
                autosize
                classNames={{ section: style.section, input: style.input }}
              />
              <Arrow onClick={handleSendMessage} className={style.arrow} />
              {showEmojiPicker && (
                <div ref={emojiPickerRef} className={style.emojiPicker}>
                  <Picker
                    i18n={i18n}
                    data={data}
                    onEmojiSelect={handleEmojiClick}
                    locale={"ru"}
                    theme={"light"}
                  />
                </div>
              )}
            </div>
            <div className={style.attachments}>
              {updateExecutionData.attachments.map(
                (doc: { content: { id: React.Key | null | undefined } }) => (
                  <DocumentCard
                    key={doc.content.id}
                    documentData={doc}
                    hasDelete={true}
                    onDelete={handleDeleteAttachment}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </Drawer.Content>
      <Drawer.Header></Drawer.Header>
    </Drawer.Root>
  );
};

export default ChatFeachersComponent;
