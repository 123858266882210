import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Paper, Notification } from "@mantine/core";
import useOrderStore from "../../store/OrderStore";
import { Order, TrackingOrders } from "../../models/List/TrackingOrders";
import { HeaderMegaMenu } from "../Header/HeaderMegaMenu";
import { TableSort } from "../ListComponents/ListInfo/TableSort";
import { TaskList } from "../ListComponents/TaskList/TaskList";
import TelegramSvg from "./TelegramSvg";
import { useMediaQuery } from "@mantine/hooks";

import useUsersStore from "store/UserStore";
import ModalEditExecutions from "components/ListComponents/ListInfo/EditExecutions/ModalEditExecutions";
import WhatsAppSvg from "./WhatsAppSvg";
import TaskModal from "components/ListComponents/TaskList/TaskModal";
import SelectComponent from "./SelectComponent";
import ChatFeachersComponent from "components/Chat/ui/ui";
import AddOrderModal from "./feature/ui/modal";
import FullSlider from "components/Slider/FullSlider";

export function MainWindow() {
  const [isOrderLoading, setisOrderLoadig] = useState(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const navigate = useNavigate();
  const {
    orders,
    getOrders,
    selectedOrderId,
    setSelectedOrder,
    getSubLink,
    subLink,
    setTaskModalOpen,
    getOrderById,
    orderById,
    customerLogin,
    tasks,
  } = useOrderStore();
  const { whoAmI, meUser } = useUsersStore();
  const { orderId } = useParams<{ orderId: string }>();
  const { taskId } = useParams<{ taskId: string }>();
  const { orderExecutions, getOrdersExecutions } = useOrderStore();

  const [searchValue, setSearchValue] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(true);
  const [localSelectedOrderId, setLocalSelectedOrderId] = useState<
    string | null
  >(null);
  const { pathname } = useLocation();
  const chatIdMatch = pathname.match(/\/chat\/([^/]+)/);
  const chatId = chatIdMatch ? chatIdMatch[1] : "";
  useEffect(() => {
    if (orderId !== undefined) {
      getOrdersExecutions(orderId);
    }
    getSubLink();
  }, [orderId, getOrdersExecutions]);

  useEffect(() => {
    if (customerLogin) {
      getOrders(0, 100, searchValue, customerLogin);
    } else {
      getOrders(0, 100);
    }
    whoAmI(navigate);
  }, [customerLogin]);

  useEffect(() => {
    if (taskId) {
      setTaskModalOpen(true);
    } else {
      setTaskModalOpen(false);
    }
  }, [taskId, setTaskModalOpen]);

  useEffect(() => {
    const loadOptions = async () => {
      const orderExists = orders?.items.find(
        (item: Order) => item.id === orderId
      );

      const updatedOptions = (orders?.items || []).map((item: Order) => ({
        value: item.id,
        label: item.number,
      }));

      if (orderById && !orderExists) {
        updatedOptions.push({
          value: orderById.id,
          label: orderById.number,
        });
      }

      setOptions(updatedOptions);
    };

    if (orders?.items) {
      loadOptions();
    }
  }, [orderId, orders?.items, orderById]);

  useEffect(() => {
    if (orderId) {
      setLocalSelectedOrderId(orderId);
      getOrderById(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    setSelectedOrder(localSelectedOrderId);
    if (localSelectedOrderId !== null && !taskId && !chatId) {
      navigate(`/orders/${localSelectedOrderId}`);
    }
  }, [localSelectedOrderId]);

  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <div className="">
      <HeaderMegaMenu />
      <Grid columns={10} className="m-0 md:m-8 ">
        <Grid.Col span={{ base: 10, md: 7, lg: 7 }}>
          <Paper shadow="xs" radius="lg" p="xs">
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
                {options && (
                  <SelectComponent
                    options={options}
                    getOrders={getOrders}
                    setLocalSelectedOrderId={setLocalSelectedOrderId}
                    localSelectedOrderId={localSelectedOrderId}
                    orderById={orderById ?? undefined}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 5 }}>
                <div className="xl:mb-5 md:mb-2 sm:mb-1">
                  <Button
                    variant="outline"
                    radius="md"
                    fullWidth
                    onClick={() => {
                      window.open(subLink, "_blank");
                    }}
                  >
                    <div className="flex gap-3 items-center	">
                      <div>订阅通知</div>
                      <div>
                        <TelegramSvg />
                      </div>
                    </div>
                  </Button>
                </div>
              </Grid.Col>
            </Grid>
            {isMobile && (
              <div className="">
                {meUser?.authorities.includes("SEND_CUSTOMER_NOTIFICATION") &&
                  orderById?.customer?.messengerLink && (
                    <div className="pb-2">
                      <a
                        href={orderById.customer.messengerLink}
                        target="/blank"
                      >
                        <Button
                          variant="outline"
                          radius="md"
                          fullWidth
                          color="green"
                        >
                          <div className="flex gap-3 items-center">
                            <div>给客户写信</div>
                            <WhatsAppSvg />
                          </div>
                        </Button>
                      </a>
                    </div>
                  )}
                <TaskList isLoading={isOrderLoading} />
                {tasks?.totalCount != undefined && tasks?.totalCount > 0 && (
                  <Notification
                    color="red"
                    title={"需要採取的行動"}
                    style={{ display: notificationVisible ? "" : "none" }}
                    onClose={() => setNotificationVisible(false)}
                  >
                    請及時完成所有操作，這將有助於盡快、不延誤地交付汽車。
                  </Notification>
                )}
              </div>
            )}
            <TableSort
              isLoading={isOrderLoading}
              orderExecutions={orderExecutions}
            />
          </Paper>
          {isMobile && <div style={{ display: "flex", gap: "8px" }}></div>}
        </Grid.Col>
        {!isMobile && (
          <Grid.Col span={{ base: 10, md: 3, lg: 3 }} className="">
            {meUser?.authorities.includes("SEND_CUSTOMER_NOTIFICATION") &&
              orderById?.customer?.messengerLink && (
                <div className="pb-2">
                  <a href={orderById.customer.messengerLink} target="/blank">
                    <Button
                      variant="outline"
                      radius="md"
                      fullWidth
                      color="green"
                    >
                      <div className="flex gap-3 items-center">
                        <div>给客户写信</div>
                        <WhatsAppSvg />
                      </div>
                    </Button>
                  </a>
                </div>
              )}

            <div className="pb-2">
              <Paper shadow="xs" radius="lg" p="xs">
                <TaskList isLoading={isOrderLoading} />
              </Paper>
            </div>
            {tasks?.totalCount != undefined && tasks?.totalCount > 0 && (
              <Notification
                color="red"
                title={"需要採取的行動"}
                style={{ display: notificationVisible ? "" : "none" }}
                onClose={() => setNotificationVisible(false)}
                radius="lg"
              >
                請及時完成所有操作，這將有助於盡快、不延誤地交付汽車。
              </Notification>
            )}
          </Grid.Col>
        )}
      </Grid>

      <div className="z-999">
        <FullSlider />
      </div>

      <TaskModal />

      <AddOrderModal />
      <ModalEditExecutions />
      {chatId && <ChatFeachersComponent />}
    </div>
  );
}
