import React, { useEffect, useState } from "react";
import "./globals.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

import { MainWindow } from "./components/MainWindow/MainWindow";
import { AuthenticationTitle } from "./components/AuthComponents/AuthenticationTitle";
import useAuthStore from "./store/useAuthStore";
import { Route, Routes, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import PrivateRoute from "./utils/router/privateRoute";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/orders" replace />} />
        <Route element={<PrivateRoute />}>
          <Route path="/orders/:orderId" element={<MainWindow />} />
          <Route
            path="/orders/:orderId/task/:taskId"
            element={<MainWindow />}
          />
          <Route
            path="/orders/:orderId/chat/:chatId"
            element={<MainWindow />}
          />

          <Route path="/orders" element={<MainWindow />} />
        </Route>

        <Route path="/auth" element={<AuthenticationTitle />} />
      </Routes>
    </div>
  );
}

export default App;
