import {
  ActionIcon,
  Button,
  CheckIcon,
  CloseButton,
  Combobox,
  CopyButton,
  Group,
  Input,
  InputBase,
  Tooltip,
  rem,
  useCombobox,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { Order } from "models/List/TrackingOrders";
import AddBtn from "./feature/ui/btn";
import useUsersStore from "store/UserStore";
interface SelectComponentProps {
  options: {
    value: string;
    label: string;
  }[];
  orderById?: Order;
  getOrders: (page: number, size: number, searchValue: string) => void;
  setLocalSelectedOrderId: (id: string) => void;
  localSelectedOrderId: string | null;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  getOrders,
  setLocalSelectedOrderId,
  localSelectedOrderId,
  orderById,
}) => {
  const { orderId } = useParams<{ orderId: string }>();
  const { meUser } = useUsersStore();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === "keyboard") {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex("active");
      }
      combobox.focusSearchInput();
    },
  });

  const [value, setValue] = useState<string>("");

  const selectedLabel =
    options.find((item) => item.value === localSelectedOrderId)?.label || "";

  const lastWord = selectedLabel.split(" ").pop();

  const data = options.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      active={item.value === localSelectedOrderId}
    >
      <Group gap="xs">
        {item.value === localSelectedOrderId && <CheckIcon size={12} />}
        <span>{item.label}</span>
      </Group>
    </Combobox.Option>
  ));

  useEffect(() => {
    if (orderId == null) {
      if (options.length > 0) {
        setLocalSelectedOrderId(options[0].value); // Set the first value from options if available
      }
    } else {
      const selectedOption = options.find((option) => option.value === orderId);
      if (selectedOption) {
        setLocalSelectedOrderId(selectedOption.value);
      } else {
        console.warn(`Option with value ${orderId} not found in options.`);
      }
    }
  }, [orderId, options, setLocalSelectedOrderId]);

  return (
    <div className="selectWrapper">
      <div className="ComboboxWrapper">
        <Combobox
          store={combobox}
          resetSelectionOnOptionHover
          onOptionSubmit={(val) => {
            setLocalSelectedOrderId(val);
            combobox.updateSelectedOptionIndex("active");
          }}
        >
          <Combobox.Target>
            <InputBase
              radius="md"
              component="button"
              type="button"
              pointer
              rightSection={<Combobox.Chevron />}
              onClick={() => combobox.toggleDropdown()}
              rightSectionPointerEvents="none"
              className="inputBase"
            >
              {selectedLabel || (
                <Input.Placeholder>输入车架号或客户进行搜索 </Input.Placeholder>
              )}
            </InputBase>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Search
              style={{ width: "100%" }}
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value);
                getOrders(0, 15, event.currentTarget.value);
              }}
              placeholder="输入车架号或客户进行搜索"
              rightSection={
                value !== "" && (
                  <CloseButton
                    size="sm"
                    style={{ pointerEvents: "auto" }}
                    onClick={() => {
                      setValue("");
                      getOrders(0, 15, ""); // Pass an empty string to clear the search
                    }}
                  />
                )
              }
            />

            <Combobox.Options>
              {data.length > 0 ? (
                data
              ) : (
                <Combobox.Empty>沒有找到任何內容</Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </div>
      {meUser?.authorities.includes("CREATE_ORDER_AUTHORITY") && (
        <AddBtn closeDropdown={combobox.closeDropdown} />
      )}
      {orderById?.identifier && (
        <CopyButton
          value={orderById?.identifier ?? lastWord ?? ""}
          timeout={2000}
        >
          {({ copied, copy }) => (
            <Button radius="md" color={copied ? "teal" : "blue"} onClick={copy}>
              {copied ? "车身号码已复制" : "复制车身号码"}
            </Button>
          )}
        </CopyButton>
      )}
    </div>
  );
};
/* */
export default SelectComponent;
