import { FC, useRef } from "react";
import { Modal } from "@mantine/core";
import { Carousel, Embla } from "@mantine/carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import style from "./myslider.module.scss";
import { useMediaQuery } from "@mantine/hooks";
import useOrderStore from "store/OrderStore";
import { IconPlayerPlay } from "@tabler/icons-react";

const FullSlider = ({}) => {
  const {
    isModalOpen,
    closeModal,
    orderExecutions,
    currentPhotos,
    photoIndex,
  } = useOrderStore();
  const beforeStartIndex =
    currentPhotos?.slice(0, photoIndex || undefined) ?? [];
  const afterStartIndex = currentPhotos?.slice(photoIndex || undefined) ?? [];

  const finalImg =
    currentPhotos && currentPhotos.length > 1
      ? [...afterStartIndex, ...beforeStartIndex].map((photo) => ({
          original: photo.original,
          thumbnail: photo.thumbnail,
          type: photo.type || "image",
        }))
      : currentPhotos
      ? currentPhotos.map((photo) => ({
          original: photo.original,
          thumbnail: photo.thumbnail,
          type: photo.type || "image",
        }))
      : [];

  const emblaRef = useRef<Embla | null>(null);
  const emblaSmallRef = useRef<Embla | null>(null);

  const scrollToSlide = (index: number, type: string) => {
    console.log("index", index, type);
    if (emblaRef.current) {
      emblaRef.current.scrollTo(index, false);
    }

    if (emblaSmallRef.current) {
      emblaSmallRef.current.scrollTo(index, false);
    }
  };

  const handleSlideChange = (type: "big" | "small") => {
    console.log(`Handling slide change for: ${type}`);

    if (type === "big" && emblaRef.current) {
      const currentIndex = emblaRef.current.selectedScrollSnap();
      console.log(`Big carousel current index: ${currentIndex}`);
      scrollToSlide(currentIndex, type);
    }

    if (type === "small" && emblaSmallRef.current) {
      const currentIndex = emblaSmallRef.current.selectedScrollSnap();
      console.log(`Small carousel current index: ${currentIndex}`);
      scrollToSlide(currentIndex, type);
    }
  };

  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const isTablet = useMediaQuery(`(max-width: 1024px)`);
  const slideSize = isMobile ? "33%" : isTablet ? "25%" : "15%";

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={closeModal}
        size="100%"
        className="z-350"
        centered
      >
        <div className={style.carouselWrapper}>
          <div className={style.mainCarouselContainer}>
            <Carousel
              controlsOffset="md"
              slideSize="90%"
              slideGap="md"
              controlSize={34}
              slidesToScroll={1}
              getEmblaApi={(embla) => (emblaRef.current = embla)}
              className={style.carouselMain}
              classNames={{
                viewport: style.viewport,
                container: style.container,
                slide: style.slide,
              }}
              onSlideChange={(index: number) => handleSlideChange("big")}
            >
              {finalImg.map(
                (
                  item: {
                    original: string | undefined;
                    thumbnail: string | undefined;
                    text?: any;
                    type: string;
                  },
                  index: number
                ) => (
                  <Carousel.Slide key={index} className={style.mainSlide}>
                    {item.type === "photo" ? (
                      <LazyLoadImage
                        alt={``}
                        effect="blur"
                        src={item.original}
                        className={style.mainImage}
                      />
                    ) : (
                      <video
                        controls
                        width="100%"
                        style={{
                          width: "100%",
                        }}
                        src={item.original}
                        className={style.originalVideo}
                      />
                    )}
                  </Carousel.Slide>
                )
              )}
            </Carousel>
          </div>
          <div className={style.thumbnailCarouselContainer}>
            <Carousel
              withControls={false}
              controlsOffset="xs"
              align="start"
              orientation="vertical"
              className={style.carouselThumbnail}
              classNames={{ viewport: style.thumbnailViewport }}
              slideSize={slideSize}
              slidesToScroll={1}
              getEmblaApi={(embla) => (emblaSmallRef.current = embla)}
              onSlideChange={(index: number) => handleSlideChange("small")}
            >
              {finalImg.map(
                (
                  item: {
                    original: string | undefined;
                    thumbnail: string | undefined;
                    text?: any;
                    type: string;
                  },
                  index: number
                ) => (
                  <Carousel.Slide
                    key={index}
                    onClick={() => scrollToSlide(index, "small")}
                  >
                    {item.type === "photo" ? (
                      <LazyLoadImage
                        alt={`Thumbnail ${item.text ?? ""}`}
                        effect="blur"
                        src={item.thumbnail}
                        className={style.thumbnailImage}
                      />
                    ) : (
                      <div className={style.videoContainer}>
                        <video
                          muted
                          src={item.thumbnail}
                          className={style.thumbnailVideo}
                        />
                        <div className={style.overlay}>
                          <button className={style.playButton}>
                            <IconPlayerPlay stroke={2} />
                          </button>
                        </div>
                      </div>
                    )}
                  </Carousel.Slide>
                )
              )}
            </Carousel>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FullSlider;
