import { AxiosError } from "axios";
import { el } from "date-fns/locale";
import { IUser, IUsers } from "models/User/IUser";
import UserService from "services/UserService";
import { create } from "zustand";

interface UserStoreState {
  allUsers: IUser[] | null;
  meUser: IUser | null;
  brokerUsers: IUser[] | null;
  customerUsers: IUser[] | null;
  getUsers: (role?: string) => void;
  whoAmI: (navigate: (path: string) => void) => void;
}

const useUsersStore = create<UserStoreState>((set) => ({
  allUsers: null,
  brokerUsers: null,
  customerUsers: null,
  meUser: null,

  whoAmI: async (navigate: (path: string) => void) => {
    try {
      const response = await UserService.whoAmI();
      set({ meUser: response.data.user });
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        navigate && navigate("/auth");
      } else {
        console.error("Error receiving orders:", error);
      }
    }
  },
  getUsers: async (role?: string) => {
    try {
      const response = await UserService.getUsers(role);
      if (role === "BROKER") {
        set({ brokerUsers: response.data.items });
      } else if (role === "CUSTOMER") {
        set({ customerUsers: response.data.items });
      } else {
        set({ allUsers: response.data.items });
      }
    } catch (err) {
      console.error("Error get users:", err);
    }
  },
}));

export default useUsersStore;
