import React, { FC, useState, useEffect } from "react";
import "@mantine/core/styles.css";

import {
  TextInput,
  PasswordInput,
  Container,
  Paper,
  Title,
  Button,
} from "@mantine/core";
import useAuthStore from "../../store/useAuthStore";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import useOrderStore from "store/OrderStore";

export const AuthenticationTitle = ({}) => {
  const { setUsername, setPassword, login, error, setError } = useAuthStore();
  const { setCustomerLogin } = useOrderStore();
  const [username, setUsernameLocal] = useState("");
  const [password, setPasswordLocal] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const encodedLogin = params.get("login");

    if (encodedLogin) {
      try {
        const decodedLogin = atob(encodedLogin);
        setUsernameLocal(decodedLogin);
        setCustomerLogin(decodedLogin);
      } catch (error) {
        console.error("Login decoding error:", error);
      }
    }
  }, [location.search]);

  const handleLogin = async () => {
    try {
      setUsername(username);
      setPassword(password);
      setError(null);

      await login();
      navigate("/orders");
    } catch (error) {
      console.error("Authorization error:", error);
      setError("使用者名稱或密碼無效");
    }
  };
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const login = params.get("login");

    if (accessToken && window.location.pathname === "/auth" && login) {
      navigate("/orders", { replace: true });
    }
  }, [accessToken, navigate]);
  return (
    <Container size={420} my={40}>
      <Title ta="center" className={"title"}>
        授權
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          error={error}
          label="使用者名稱"
          placeholder="user"
          required
          value={username}
          onChange={(e) => {
            setUsernameLocal(e.target.value);
          }}
        />
        <PasswordInput
          error={error}
          label="密碼"
          placeholder="***"
          required
          mt="md"
          value={password}
          onChange={(e) => {
            setPasswordLocal(e.target.value);
          }}
        />

        <Button fullWidth mt="xl" onClick={handleLogin}>
          登录
        </Button>
      </Paper>
    </Container>
  );
};
